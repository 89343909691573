'use client';

import useApi, { Methods } from '@/global/hooks/useApi';
import React, { createContext, useContext, useState, useEffect } from 'react';

type User = any;

interface AuthContextType {
   user: User | null;
   setUser: (user: User | null) => void;

   role: string;
   filterPlanning: any;
   setFilterPlanning: (filterPlanning: any | null) => void;
}

const AuthContext = createContext<AuthContextType>({
   user: null,
   setUser: () => {},
   role: '',
   filterPlanning: null,
   setFilterPlanning: () => {},
});

interface AuthProviderProps {
   children: React.ReactNode;
   initialUser?: User;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
   children,
   initialUser = '',
}) => {
   const { response, error, loading, callApi } = useApi();

   const [user, setUser] = useState<User | null>(initialUser);
   const [role, setRole] = useState<string>('');
   const [filterPlanning, setFilterPlanning] = useState<any>();

   const handleSetUser = (newUser: User | null) => {
      setUser(newUser);
   };

   const handleSetFilterPlanning = (newFilterPlanning: any | null) => {
      setFilterPlanning(newFilterPlanning);
      callApi(Methods.PUT, '/api/planning', newFilterPlanning);
   };

   useEffect(() => {
      if (user && user.team?.TeamsUsersLink) {
         const findUserInTeam = user.team.TeamsUsersLink.find(
            (element: any) => element.user.id === user.id
         );
         if (findUserInTeam && findUserInTeam.role_name) {
            setRole(findUserInTeam.role_name);
            if (findUserInTeam && findUserInTeam.filterPlanning) {
               setFilterPlanning(JSON.parse(findUserInTeam.filterPlanning));
            }
         }
      } else {
         return;
      }
   }, [user]);

   return (
      <AuthContext.Provider
         value={{
            user,
            setUser: handleSetUser,
            filterPlanning,
            setFilterPlanning: handleSetFilterPlanning,
            role,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => {
   const context = useContext(AuthContext);
   if (context === undefined) {
      throw new Error('useAuth must be used within an AuthProvider');
   }
   return context;
};
